@media screen

  .kontaktseite
    +sm
      .col-sm-6
        &:first-child
          padding-right: 30px
          border-right: 1px solid #cccccc
        &:last-child
          padding-left: 30px

  body .creative_form_18 a, .creative_form_18 .ccf_popup_link, .ccf_popup_link_18
    color: $col-blue !important

  body .creative_form_18 a:hover, .creative_form_18 .ccf_popup_link:hover, .ccf_popup_link_18:hover
    color: darken($col-blue, 10%) !important
    border: none !important
    text-decoration: underline !important

  .creativecontactform_wrapper
    margin-top: 0 !important