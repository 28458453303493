@import "bourbon/bourbon"

/* open-sans-300 - latin */
@font-face 
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 300
  src: url('../fonts/open-sans-v15-latin-300.eot')
  src: local('Open Sans Light'), local('OpenSans-Light'),url('../fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v15-latin-300.woff2') format('woff2'), url('../fonts/open-sans-v15-latin-300.woff') format('woff'), url('../fonts/open-sans-v15-latin-300.ttf') format('truetype'), url('../fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg')

/* open-sans-regular - latin */
@font-face 
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 400
  src: url('../fonts/open-sans-v15-latin-regular.eot')
  src: local('Open Sans Regular'), local('OpenSans-Regular'),url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg')

/* open-sans-italic - latin */
@font-face 
  font-family: 'Open Sans'
  font-style: italic
  font-weight: 400
  src: url('../fonts/open-sans-v15-latin-italic.eot')
  src: local('Open Sans Italic'), local('OpenSans-Italic'),url('../fonts/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v15-latin-italic.woff2') format('woff2'), url('../fonts/open-sans-v15-latin-italic.woff') format('woff'), url('../fonts/open-sans-v15-latin-italic.ttf') format('truetype'), url('../fonts/open-sans-v15-latin-italic.svg#OpenSans') format('svg')

/* open-sans-700 - latin */
@font-face 
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 700
  src: url('../fonts/open-sans-v15-latin-700.eot')
  src: local('Open Sans Bold'), local('OpenSans-Bold'),url('../fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'), url('../fonts/open-sans-v15-latin-700.woff') format('woff'), url('../fonts/open-sans-v15-latin-700.ttf') format('truetype'), url('../fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg')


@import "mixins"
@import "settings"

@media screen

  html
    font-size: 100%

  html, body
    height: 100%
    width: 100%

  body
    font-family: $font-open-sans
    overflow-x: hidden
    +font-size-rem(14)
    +md
      +font-size-rem(15)
    +lg
      +font-size-rem(16)



  a
    transition: color .3s, background .3s
    //color: $col-blue
    //&:focus,
    //&:active,
    //&:hover
      color: lighten($col-blue, 10%)

  //+xlg
    .container
      width: 1370px

  +xxlg
    .container
      width: 1570px

  /*- Allgemein -----------------------------------------------------------------------*/
  .rel
    position: relative
    width: 0
    height: 0
  .abs
    position: absolute
  .clear
    clear: both

  /*- Overlay -----------------------------------------------------------------------*/
  .overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0,0,0,0.8)
    display: none
    z-index: $z-overlay


@import "content"
@import "header"
@import "website-navigation"
@import "navigation"
@import "footer"
@import "map"
@import "team"
@import "galerie"
@import "hinweise"
@import "formular"

