@media screen

  .container-fluid
    position: relative
    +xlg
      max-width: 1800px

  .content-shift
    +sm
      +margin(null auto)
      width: 90%
      position: relative
      top: -150px
      margin-bottom: -150px
      background: white
      padding: 30px
      +lg
        width: 85%
      +xlg
        width: 80%
      +xxlg
        width: 75%
      > div
        padding: 1px 30px 30px 30px
        min-height: 300px
        //+lg
        //  min-height: 650px
        //+xlg
        //  min-height: 700px
        //+xxlg
        //  min-height: 800px

  //.wasserzeichen
    +sm
      background: url('/templates/dentalwerkstaettentemplate/img/layout/zahn-einzeln-alpha.jpg') no-repeat center 30%
      background-size: 40%

  .content-main
    color: $col-grey
    margin-top: 15px
    margin-bottom: 15px
    p
      line-height: 1.8
    //+sm
      margin-bottom: 30px
    &.content-main-bg
      +sm
        > div
          background: $col-grey-bg
        &:after
          content: ''
          width: 100%
          height: 115px
          background: white
          position: absolute
          bottom: 0

    .page-header:first-of-type
      margin-top: 0

    .page-header
      margin-bottom: 0
      border-bottom: none

      & + div
        h2:first-of-type
          margin-top: 0

    h1
      color: $col-blue
      font-weight: bold
      font-size: 1.8em
      text-align: center
      margin-top: 0

    &.content-main-bg
      h1
        margin-top: 30px

    h2
      font-size: 1.4em
      color: $col-red
      font-style: italic

  .content-inner-top
    p
      margin-bottom: 0

  /*- Kacheln Startseite -----------------------------------------------------------------------*/
  .container-index-links
    margin-top: 15px
    margin-bottom: 0
  .index-kachel-item
    +xxs
      width: 100%

    img
      max-width: 100%

    &:nth-child(3),
    &:nth-child(4)
      margin-top: 15px

    +xxs
      &:nth-child(2)
        margin-top: 15px
    +md
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4)
        margin-top: 0
  .kachel-link
    margin-top: 5px
    position: relative
    height: 0
    padding-bottom: percentage(1 / 500 * 315)

    a
      display: block
      background: $col-grey
      background: url("/templates/dentalwerkstaettentemplate/img/layout/zahn-einzeln-150.png") no-repeat center 10%, linear-gradient($col-grey-bg, $col-grey-bg)
      background-size: 20%, 100%
      +position(absolute, 0)
      +size(100%)
      color: $col-grey
      font-size: 1.8em
      font-size: 4vw
      +xxs
        font-size: 8vw
      +md
        font-size: 1.4em
      +lg
        font-size: 1.5em
      +xlg
        font-size: 1.7em

      > div
        +position(absolute, 70% null null 50%)
        transform: translate(-50%, -50%)
        text-align: center

      &:focus,
      &:active,
      &:hover
        background: $col-grey
        background: url("/templates/dentalwerkstaettentemplate/img/layout/zahn-einzeln-150.png") no-repeat center 10%, linear-gradient($col-grey, $col-grey)
        background-size: 20%, 100%
        color: $col-grey-bg

  /*- historie -----------------------------------------------------------------------*/

  .jux-tl-control
    background: url('/templates/dentalwerkstaettentemplate/img/layout/plus-red.png') no-repeat scroll 0 0 transparent !important
  .jux-tl-item.selected .jux-tl-control
    background: url('/templates/dentalwerkstaettentemplate/img/layout/minus-red.png') no-repeat scroll 0 0 transparent !important

