@media screen

  .map-rahmen
    margin-top: 15px
    height: 300px
    +xlg
      height: 350px
    background: url('../img/inhalt/map-ersatz.jpg') center center no-repeat
    background-size: cover
    position: relative

  .map-hinweis
    +position(absolute, 50% null null 50%)
    transform: translate(-50%, -50%)
    background: rgba(white, .8)
    border-radius: 4px
    width: 90%
    max-width: 700px
    +font-size-rem(12)
    text-align: center
    padding: 5px
    button
      margin-top: 5px



