@media screen

  .wrap
    > header
      position: relative
      padding-top: 10px

      .header-logo
        img
          max-width: 100%

  /*- Logo -----------------------------------------------------------------------*/
  .header-logo
    position: relative
    +sm
      height: 102px
      +md
        height: 143px
      +lg
        height: 163px
      +xlg
        height: 184px
      .bg-rahmen
        +position(absolute, null null 0 0)
        background: $col-grey-bg
        width: 100%
      img
        background: white
        position: relative
        +padding(null 15px)
        left: 30px
        +md
          left: 50px
        +lg
          left: 65px

  .balken-height
    +sm
      height: 27px
    +md
      height: 37px
    +lg
      height: 42px
    +xlg
      height: 48px

  /*- Kontakt -----------------------------------------------------------------------*/
  .header-kontakt
    white-space: nowrap

    p
      margin-bottom: 0
      padding-top: 1px

    +xs
      background: $col-grey-bg
      height: 6.5vw
      margin-top: 5px
      position: relative
      > div
        width: 100%
        text-align: center
        +position(absolute, 50% null null 0)
        transform: translateY(-50%)
    +xs2
      height: 40px
      +font-size-rem(22px)
      > div
        text-align: left
        padding-left: 3vw
      .kontakt-desktop
        display: none
    +sm
      +position(absolute, null 30px 0 null)
      +md
        right: 50px
      +lg
        right: 60px
      > div
        +position(absolute, 50% 0 null null)
        transform: translateY(-50%)

    .glyphicon
      color: #848282

    a
      color: $col-grey
      display: inline-block

      &:first-child
        margin-right: 10px
        +xxs
          margin-right: 5px
        +md
          margin-right: 15px

      &:focus,
      &:active,
      &:hover
        color: darken($col-grey, 20%)
        text-decoration: none








