@media screen

  body
    > footer
      > div
        margin-top: 15px
        padding: 15px 0
        text-align: center
        background: $col-grey-bg

      .footer-copyright
        display: inline-block
        padding: 0 5px
        border-right: 1px solid lighten($col-grey, 10%)
        +xxs
          display: block
          text-align: center
          border-right: none

        p
          margin: 0
          color: $col-grey

      .footer-menu
        display: inline-block

        li
          display: inline-block
          border-right: 1px solid lighten($col-grey, 10%)

          a
            padding: 0 5px
            display: inline-block
            color: $col-grey

            &:focus,
            &:active,
            &:hover
              color: darken($col-grey, 20%)

          &:last-child
            border-right: none