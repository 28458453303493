@media screen

  .ie-hinweis,
  .noscript-hinweis
    position: fixed
    top: 10px
    left: 10px
    padding: 10px 15px
    border-radius: 4px
    z-index: 10000
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2)
    a
      text-decoration: underline

