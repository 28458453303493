@media screen

  .sigplus-gallery
    ul
      li
        width: calc(33.33% - 2px)
        +md
          width: calc(25% - 2px)
        +xs2
          width: calc(50% - 2px)
        +lg
          width: calc(20% - 2px)
        a
          width: 100%
          img
            width: 100%
