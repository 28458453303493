/*- Fonts -----------------------------------------------------------------------*/
$font-open-sans: 'Open Sans', sans-serif

/*- Colors -----------------------------------------------------------------------*/
//$col-grey: #686868
//$col-blue: #003A6C
//$col-green: #93C119
//$col-white-hover: #c3c3c3
$col-red: #E8424B
$col-blue: #1C6492
$col-grey-bg: #ECECEC
$col-grey: #848282


/*- Spacer -----------------------------------------------------------------------*/
$space-standard: 30px

/*- Z-Index -----------------------------------------------------------------------*/

$z-content-item: 100

$z-header: 500
$z-suche: $z-header
$z-suche-button: 810
$z-overlay: 1000
$z-navigation: 1100
