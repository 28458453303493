@media screen

  .team-header
    clear: both
    width: 100%
    max-width: 100%
    margin-top: 15px
    margin-bottom: 30px
    +clearfix

  .team-header-single
    width: 500px
    +margin(null auto)

  .t-header
    background: $col-grey-bg
    text-align: center
    padding: 10px
    margin-bottom: 15px
    position: relative
    &:before
      content: ""
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      border-bottom: 15px solid black
      border-bottom-color: white
      border-left: 15px solid transparent
      border-right: 15px solid transparent

    h2
      color: darken($col-grey-bg, 20%)
      font-style: normal
      font-weight: bold
      text-transform: uppercase

  .t-content
    img
      width: 100%
      max-width: 100%
    > div
      font-size: 20px
      text-align: center
      padding: 10px

  .team-header:not(.team-header-single)
    .t-content
      float: left
      width: 50%
      +xxs
        width: 100%
      +lg
        width: percentage(1 / 3)







