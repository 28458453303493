//Sass Datei für alle Mixins

@function strip-units($number)
  @return $number / ($number * 0 + 1)

//Beliebige Werte in REM umrechnen
@mixin rem($property, $size)
  #{$property}: strip-units($size) / 1 + rem

//Pixel in Rem umrechnen
@mixin font-size-rem($size-in-px)
  //doppelte Angabe IE8 und Opera Mini
  font-size: strip-units($size-in-px) + px
  font-size: strip-units($size-in-px) / 16 + rem

//Line Height in px angeben
@mixin line-height($font-size-px, $line-height-px)
  line-height: strip-units($line-height-px) / strip-units($font-size-px) + px

@mixin sizes($width)
  @each $size in 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100
    .img-#{$width}-#{$size}
      width: $size * 1%

@mixin xs()
  @media screen and (max-width: 767px)
    @content

@mixin xs2()
  @media screen and (max-width: 600px)
    @content

@mixin xxs()
  @media screen and (max-width: 480px)
    @content

@mixin xxxs()
  @media screen and (max-width: 360px)
    @content

@mixin sm()
  @media screen and (min-width: 768px)
    @content

@mixin md()
  @media screen and (min-width: 992px)
    @content

@mixin lg()
  @media screen and (min-width: 1200px)
    @content

@mixin xlg()
  @media screen and (min-width: 1400px)
    @content

@mixin xxlg()
  @media screen and (min-width: 1600px)
    @content

@mixin createSize($size)
  @if $size == xs
    +xs
      @content
  @if $size == xs2
    +xs2
      @content
  @if $size == xxs
    +xxs
      @content
  @if $size == xxxs
    +xxxs
      @content
  @if $size == sm
    +sm
      @content
  @if $size == md
    +md
      @content
  @if $size == lg
    +lg
      @content
  @if $size == xlg
    +xlg
      @content
  @if $size == xxlg
    +xxlg
      @content

// Umrechnung für VW Werte
@function get-viewport-size($size)
  @if $size == xs
    @return 600
  @if $size == xs2
    @return 480
  @if $size == xxs
    @return 360
  @if $size == xxxs
    @return 300
  @if $size == sm
    @return 768
  @if $size == md
    @return 992
  @if $size == lg
    @return 1200
  @if $size == xlg
    @return 1400
  @if $size == xxlg
    @return 1600

@mixin support-attribute($attribute)
  html.#{$attribute} &
    @content

@mixin no-support-attribute($attribute)
  html.no-#{$attribute} &
    @content

// Werte in vw umwandeln nach Bildschirmbreite
@function get-vw($viewport,$target)
  $vw-context: (get-viewport-size($viewport) * 0.01)
  @return ($target / $vw-context) * 1vw

// Für einzelne Attribute Werte in vw umrechnen
@mixin vw-attribute($viewport, $attribute, $size)
  #{$attribute}: ($size)px
  #{$attribute}: get-vw(get-viewport-size($viewport), $size)vw

// Font-size mit Fallback REM und PX
@mixin font-size-vw($viewport, $size)
  +font-size-rem($size)
  font-size: get-vw(get-viewport-size($viewport), $size)vw

@mixin sticky-footer($height, $height-footer, $height-subfooter)
  html, body
    height: 100%
  .wrap
    min-height: 100%
    height: auto
    margin: 0 auto -#{$height} auto
    padding: 0 0 #{$height} 0
  .footer
    height: $height
    .haupt-footer
      height: $height-footer
    .sub-footer
      height: $height-subfooter