@media screen

  /*- Allgemein -----------------------------------------------------------------------*/
  .haupt-navi-nav-rahmen

    // ul erste Ebene
    > ul

      // li erste Ebene
      > li

        // a erste Ebene
        > a
          text-transform: uppercase

      li

        // alle Links
        a
          color: $col-grey

          &:focus,
          &:active,
          &:hover
            color: darken($col-grey, 20%)

  /*- Desktop -----------------------------------------------------------------------*/
  +sm
    .haupt-navi-nav-rahmen
      margin-top: 15px
      text-align: center

      +no-support-attribute(touchevents)
        .link-uebersicht
          display: none

      // ul erste Ebene
      > ul

        // li erste Ebene
        > li
          float: none
          display: inline-block

          // a alle
          white-space: nowrap

          // a erste Ebene
          > a
            font-size: 1.1em
            +padding(null 20px)
            +md
              +padding(null 25px)

            &:focus,
            &:active,
            &:hover
              background: none

          &:hover
            > a
              background: $col-grey-bg

          // ul zweite Ebene
          > .submenu-rahmen > ul
            text-align: left
            background: white
            border-radius: 8px
            border: 2px solid $col-blue
            padding: 10px 0 30px 0

            &:after
              content: url("../img/layout/zahn-einzeln.png")
              margin-left: 2px
              +position(absolute, null null -22px 50%)
              transform: translateX(-50%)

            &:before
              content: ''
              background: white
              width: 50px
              height: 2px
              +position(absolute, null null -2px 50%)
              transform: translateX(-50%)

            // li zweite Ebene
            > li
              list-style: inherit

              // a zweite Ebene
              a
                position: relative
                padding: 3px 15px 3px 25px
                font-size: 0.9rem

                &:before
                  content: '\2022'
                  color: $col-grey
                  font-weight: bold
                  display: inline-block
                  width: 1em
                  margin-left: -1em
                  transition: color 0.3s

                &:focus,
                &:active,
                &:hover
                  background: $col-red
                  color: white

                  &:before
                    color: white

              > ul
                > li
                  a
                    font-size: 0.9rem
                    padding-left: 45px
                    &:before
                      content: '-'

            .link-uebersicht
              .glyphicon
                color: lighten($col-grey, 30%)




  /*- Mobile -----------------------------------------------------------------------*/
  +xs
    .btn-navi-menu
      border: none
      padding-top: 3px
      +xs2
        //margin-right: -15px
        padding-right: 4vw

    .container-navigation
      position: relative
      height: 0
      margin-bottom: 10px

    .haupt-navi-rahmen
      position: absolute
      //width: 100%
      top: -6.5vw
      right: 0
      height: 6.5vw
      +xs2
        top: -40px
        height: 40px
      display: inline-block
      float: right

      .haupt-navi-rahmen-mobile
        padding-right: 0
        +position(absolute, 50% 15px null null)
        transform: translateY(-50%)
        +xs2
          right: 0

    .haupt-navi-rahmen-inner
      background: white

    .haupt-navi-nav-rahmen

      // ul erste Ebene
      > ul

        // li erste Ebene
        > li
          +font-size-rem(20)

          &.open
            > a,
            > a:focus,
            > a:active,
            > a:hover
              background: none

          > a,
          > a:focus,
          > a:active,
          > a:hover
            background: none

          // ul zweite Ebene
          ul
            padding-left: 30px

            &:before
              right: 15px

            // li zweite Ebene
            li
              +font-size-rem(18)

              // a zweite Ebene
              a
                position: relative
                +padding(3px null)

                &:before
                  position: absolute
                  left: -8px
                  content: '-'

            .link-uebersicht
              .glyphicon
                color: lighten($col-grey, 30%)











